import { render, staticRenderFns } from "./zh.vue?vue&type=template&id=34966f4d&scoped=true&"
import script from "./zh.vue?vue&type=script&lang=js&"
export * from "./zh.vue?vue&type=script&lang=js&"
import style0 from "./zh.vue?vue&type=style&index=0&id=34966f4d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34966f4d",
  null
  
)

export default component.exports