export default {
    home: {
        search: 'Search'
    },
    header: {
        home: 'Home',
        thesis: 'Thesis',
        category: 'Category',
        statistics: 'Statistics',
        manage: 'Manage',
        approve: 'Approve Upload',
        maintain: "System Maintenance",
        login: 'Login',
        logout: 'Logout',
        confirmLogout: 'Confirm Logout?',
        confirm: 'Confirm',
        cancel: 'Cancel',
        logoutSuccess: 'Logout Success',
        logoutCancelled: 'Logout Cancelled'
    },
    footer: {
        about: 'About',
        term: 'Terms of Service',
        privacy: 'Privacy Policy',
        site: 'Yunhists'
    },
    login: {
        accountLogin: 'Sign In',
        email: 'Email',
        password: 'Password',
        forgotPassword: 'Forgot password?',
        login: 'Login',
        registerAccount: 'Sign Up',
        username: 'Username',
        confirmPassword: 'Confirm Password',
        verificationCode: 'Verification Code',
        send: 'Send',
        register: 'Register',
        noAccount: 'New user?',
        haveAccount: 'Have account?',
        enterEmail: 'Please enter email',
        invalidEmail: 'Invalid email',
        enterPwd: 'Please enter password',
        incorrectPwd: 'Incorrect password',
        confirm: 'Confirm',
        emailNotRegistered: 'This email hasn\'t been registered',
        googleAccount: 'This account was registered with Google, please login with Google',
        emailAccount: 'This account was registered with email address, please login with password',
        welcome: 'Welcome',
        missUsername: 'Please enter username',
        usernameLengthNotMatch: 'Length between 1 - 15',
        missPassword: 'Please enter password',
        passwordLengthNotMatch: 'Password at least 6 digits',
        missConfirmPassword: 'Please enter password again',
        passwordNotMatch: 'Password not matched',
        missVerificationCode: 'Please enter verification code',
        verificationNotMatch: 'Verification code is 6 digits number',
        registerSuccess: "Register success",
        noVerificationCodeSend: "Never sent a verification code to this email address before",
        emailRegistered: "The email address already registered",
        incorrectCode: "Verification code incorrect",
        codeExpired: "Verification code expired",
        codeSent: "Verification code sent, if there is no email in the inbox, please check the spam",
        waitCountDown: "Please re-send after the countdown",
        sendEmailFail: "Send email fail, please check email address",
        resetPwd: "Reset Password",
        reset: "Reset",
        newPwdSent: "New password sent, if there is no email in the inbox, please check the spam",
        waitSendEmail: "Email already sent, please re-send after one minute",
        caution: "Caution",
        cancel: "Cancel",
        cautionTxt1: "Please read the ",
        cautionTxt2: "terms of service",
        cautionTxt3: " and ",
        cautionTxt4: "privacy policy",
        cautionTxt5: ". If you continue to register, you will be deemed to agree and accept the relevant regulations of this site.",
    },
    profile: {
        userInfo: "User Info",
        myUpload: "My Upload",
        userId: "ID",
        username: "Username",
        change: "Change",
        email: "Email",
        onlyEmailRegisteredCanChange: "Only email-registered user can change their email address, and Google-registered user cannot.",
        password: "Password",
        langSetting: "Language",
        userRightsGroup: "User Rights Group",
        pointsLevel: "Points & Level",
        levelTotal: "There are 20 levels, and you can get points for uploading new papers into the system.",
        comma: ", ",
        points: " points",
        confirmDeleteAccount: "Confirm Delete Account",
        confirmDeleteAccountInfo: "If you confirm to delete your account, your email address, password, and username in the database will be cleared, you will not be able to log in to the account, and your username will not be visible in the upload records. Before deleting, please remember your ID, if you want to restore your account, please contact the admin by email and provide your user ID.",
        confirm: "Confirm",
        cancel: "Cancel",
        accountDeleted: "Account Deleted",
        changeUsername: "Change Username",
        inputNewUsername: "Please input new username",
        changeEmail: "Change Email",
        inputNewEmail: "Please input new email",
        inputAccountPwd: "Please input account password",
        inputVerificationCode: "Please input verification code",
        changePassword: "Change Password",
        inputOldPwd: "Please input old password",
        inputNewPwd: "Please input new password",
        inputNewPwd2: "Please input new password again",
        usernameSame: "Username same",
        usernameLength: "Username length between 1 - 15",
        changeSuccess: "Change success",
        emailSame: "Email same",
        invalidEmail :"Invalid email",
        codeSent: "Verification code sent, if there is no email in the inbox, please check the spam",
        waitCountDown: "Please re-send after the countdown",
        sendEmailFail: "Send email fail, please check email address",
        input6VerificationCode: "Please input 6 digits verification code",
        incorrectPwd: 'Incorrect password',
        codeExpired: "Verification code expired",
        incorrectCode: "Verification code incorrect",
        noVerificationCodeSend: "Never sent a verification code to this email address before",
        newPwdNotMatch: "New password not match",
        passwordLengthNotMatch: "Password at least 6 digits",
        send: "Send",
        emailRegistered: "The email address already registered",
        account: "Account",
        registration: "Registration",
        emailRegistration: "by Email",
        googleRegistration: "by Google",
        preferenceSetting: "Preference Settings",
        settings: "Settings",
        description: "Description",
        operation: "Operation",
        on: "ON",
        off: "OFF",
        lang: "Language",
        langDesc: "The language in which the website is displayed and in which emails will also be sent.",
        receiveEmail: "Receive Notification Email",
        receiveEmailDesc: "Receive notification emails for success, failure, and deletion of thesis uploads.",
        deleteAccount: "Delete Account",
        deleteAccountDesc: "Your email, password, and user name in the database will be cleared, you will not be able to log in to your account, and your user name will not be visible in the upload records.",
        pointsRequire1: "",
        pointsRequire2: " points are required to advance to the next level"
    },
    util: {

        permission: {
            noLogin: "You are not logged in!",
            noPermission: "You don't have permission to this page."
        },
        request: {
            serverError: "Server Error",
            noUser: "User not exist, please login again",
            tokenExpired: "Token expired, please login again",
            tokenError: "Token error, please login again",
            missingToken: "Token not exist, please login again",
            unknownError: "Unknown error, please login again",
            loginAgain: "Please login again",
            noPermission: "You Don't Have Permission!",
            unexpectedError: "Unexpected Error",
            timeout: "Timeout, please check your internet"
        }
    },
    category: {
        addCategory: "Add Category",
        zhName: "Chinese Name",
        enName: "English Name",
        parentCat: "Parent Category",
        confirm: "Confirm",
        noPermissionVisit: "You don't have permission to this page",
        inputZhName: "Please input Chinese name",
        inputEnName: "Please input English name",
        addSuccess: "Add Success",
        zhCatExist: "This Chinese category already exists",
        enCatExist: "This English category already exists",
        inputCatName: "Please input category name",
        searchCatName: "Please search the English name",
        operate: "Operation",
        add: "Add",
        thesesCount: "Theses",
        subCatsCount: "SubCats",
        batchOperate: "Batch Operation",
        forFollowingCats: "For the following category(s):",
        addNewCommonParentCats: "Add common parent category(s)",
        cancel: "Cancel",
        rows: " Rows",
        selectCategory: "Please select the category you want to operation on",
        selectParentCat: "Please select the common parent category you want add to",
        batchSuccess: "Batch Operation Success",
        error: "Error",
        followingFails: "Following update failed: ",
        selectedCat: "Selected category ",
        notInDatabase: " is not in the database.",
        connectTo: " connect to ",
        failedBecause: " failed, because: ",
        canNotBeCatItself: "Can not be the category of itself",
        relationExist: "The relationship already exist",
        noData: "No Data",
        tips: "Tips",
        clickGoToDetailPage: "Click on the category name to enter the details page",
        homeCat: "Top Category",
        categoryIdNotExist: "Category ID not exist",
        invalidId: "Invalid ID",
        edit: "Edit",
        updateCatName: "Edit Category Name",
        updateSuccess: "Update Success",
        theses: "Theses",
        subCats: "SubCats",
        searchCategory: "Please search category",
        save: "Save",
        selectedCategoryExist: "Selected parent category is already exist",
        canNotBeCatItselfDetailPage_1: "Failed to add to ",
        canNotBeCatItselfDetailPage_2: " category. Reason: Can not be the category of itself",
        delete: "Delete",
        selectSubCatsAndTheses: "Please select the subcats or theses you want to operation on",
        selectedTheses: "Selected theses ",
        removeFromCurrentCat: "Remove from current category",
        confirmRemove1: "Confirm remove above items from category ",
        confirmRemove2: "?",
        moveToAnotherCat: "Move to another category",
        noNeedMove: "Selected item is already under this category, no need to move",
        alreadyInThisCatDetailPage: "Already in this category page",
        confirmDeleteCat: "Confirm delete category?",
    },
    thesis: {
        dragOr: "Drag file here, or",
        clickUpload: " Click to Upload",
        onlyUploadOne: "Can only upload one file",
        removeSuccess: "Remove success",
        error: "Error",
        uploadSuccess: "Upload success",
        confirm: "Confirm",
        addThesis: "Add Thesis",
        author: "Author",
        title: "Title",
        publication: "Publication",
        type: "Published on",
        journal: "Journal",
        collection: "Collection",
        bookChapter: "Book",
        newspaperArticle: "Newspaper",
        location: "Location",
        publisher: "Publisher",
        year: "Year",
        select: "Select",
        volume: "Volume",
        issue: "Issue",
        pages: "Pages",
        onlinePublisher: "Online Publisher",
        onlineUrl: "Online URL",
        copyright: "Copyright",
        copyrightReserved: "All Rights Reserved",
        openAccess: "Open Access",
        publicDomain: "Public Domain",
        uploadFile: "Upload",
        category: "Category",
        noPermissionVisit: "You don't have permission to this page",
        cnki: "CNKI",
        cqvip: "CQVIP",
        selfPublish: "Self Publish",
        inputTitle: "Please input thesis title",
        pleaseUploadFile: "Please upload the file",
        thesisExist: "Thesis already exists",
        parseJSON: "Parse JSON",
        pleaseUse: "Please use the ",
        tampermonkey: "Tampermonkey script ",
        inputGeneratedJSON: "to generate JSON, then input in the textarea",
        inputJson: "Input JSON",
        cancel: "Cancel",
        parse: "Parse",
        jsonFormatError: "Json Format Error",
        autoInput: "Auto Input",
        addSuccess: "Add Success",
        invalidCatId: "Warning: following category ID invalid, may have been recently deleted: ",
        volumeIsNum: "Volume should be number",
        searchTitle: "Search Title",
        advancedSearch: "Advanced Search",
        operation: "Operation",
        add: "Add",
        upload: "Upload",
        noData: "No Data",
        yearIssue: "Issue",
        reference: "Reference",
        vancouver: "Vancouver reference style",
        copySuccess: "Copy Success",
        harvard: "Harvard reference style",
        gbt7714: "Information and Documentation - Rules for bibliographic references and citations to information resources",
        wikipedia: "Wikipedia Cite Template",
        downloadThesis: "Download Thesis",
        download: "Download",
        rows: " Rows",
        oops: "Oops, something went wrong",
        publisherAllRights: " publisher reserved all rights",
        isOpenAccess: " is open access",
        isPublicDomain: " belonging public domain",
        noMoreDownload: "You have run out of downloads for today",
        onlinePublisherIs: ". The online publisher is ",
        pleaseClick: ", please click the ",
        link: "link",
        goToPublisherPage: " to go to the publisher's web page",
        purchase: " to purchase",
        toDownload: " to download",
        dote: ".",
        weHaveResource: "The thesis belonging to public domain, we can provide the resource, please ",
        click: "click",
        login: "login",
        sorryCopyrightNoResource: "Sorry, because the paper is protected by copyright, we cannot provide resources.",
        youHave: "You have ",
        downloadChance: " chances to download today, are you sure to download?",
        youCanIncreaseDownload: "You can increase your daily download chances by ",
        uploadThesis: "upload thesis",
        youCanIncreaseDownload2: ".",
        noFileInDatabase: "The file was not found in the database, it may have been deleted",
        fileMissing: "The file for this thesis is missing, please contact the administrator",
        invalidParamInPath: "Path contains invalid param",
        updateThesis: "Update Thesis",
        thesisIdNotExist: "Thesis ID not exist",
        invalidId: "Invalid ID",
        updateSuccess: "Update Success",
        confirmDelete: "Confirm Delete",
        pleaseInputReason: "Please input the reason:",
        notMeetCriteriaTitle: "Not Match Criteria",
        notMeetCriteriaBody: "The thesis does not meet the system's inclusion criteria",
        repeatThesisTitle: "Repeat Thesis",
        repeatThesisBody: "The thesis already exists in the system",
        delete: "Delete",
        deleteSuccess: "Delete Success",
        selectThesis: "Please select the thesis you want to operation on",
        selectParentCat: "Please select the common parent category you want add to",
        batchSuccess: "Batch Operation Success",
        addNewCommonParentCats: "Add common parent category(s)",
        batchOperate: "Batch Operation",
        forFollowingTheses: "For the following thesis(s):",
        uploadNewThesis: "Upload Thesis",
        newCategory: "New Category",
        newCategoryComments: "If you can't find the category you want above, please fill it in here. At the same time, the categorisation relationship of the newly created category can be provided for the administrator's reference",
        export: "Export",
        exportInfo: "Export the thesis data in this page as:",
    },
    upload: {
        uploadSuccess: "Uploaded successfully, the approval result will notify you by email",
        pleaseSearchTitle: "Please search title",
        status: "Status",
        waitingApprove: "Waiting Approve",
        approved: "Approved",
        failed: "Rejected",
        updateUpload: "Update Uploaded Thesis",
        notYourUpload: "This is not the thesis you uploaded",
        updateSuccess: "Update Success",
        approvedUploadCanNotUpdate: "This upload has been approved and cannot be modified",
        uploadList: "Upload List",
        filterUnapproved: "Filter Unapproved Uploads",
        uploader: "Uploader",
        approver: "Approver",
        approve: "Approve",
        fileMissingTitle: "File Missing",
        fileMissingBody: "Your uploaded thesis missing the file",
        approveNewUpload: "Approve Upload",
        download: "Download",
        reject: "Reject",
        approvePass: "Approve",
        confirmReject: "Confirm Reject",
        fileMissing: "File Missing",
        checkFile: "Please download and check the file",
        approveSuccess: "Approve Success",
        inputReason: "Please input reason",
        rejectSuccess: "Reject Success",
        file: "File",
    },
    admin: {
        missingFile: "Theses without File",
        noCatThesis: "Theses without Category",
        noCatCat: "Category without Category",
        noChildCat: "Empty Category",
        noData: "No Data"
    },
    statistics: {
        thesisCount: "Thesis Total",
        categoryCount: "Category Total",
        relationshipCount: "Category Relation Total",
        userCount: "User Total",
        uploadCount: "Upload Total",
        yearLineChartTitle: "Number of theses over the years",
        typePieChartTitle: "Thesis Type Pie Chart",
        copyrightPieChart: "Thesis Copyright Pie Chart",
        comingSoon: "Coming Soon",
        year: "Year: ",
        count: "Total: ",
        dataRefreshed: "Data Refreshed",
        refreshCountdown: "Refresh Countdown",
        refreshCountdownPopup: "Refresh at 00:00 Beijing time"
    },
    title: {
        home: "Home",
        missingFile: "List of Missing File Thesis",
        noCatCat: "List of Category without Category",
        noCatThesis: "List of Thesis without Category",
        emptyCat: "Empty Category List",
        addCat: "Add Category",
        catList: "Category List",
        login: "Login",
        myUpload: "My Upload",
        userInfo: "User Info",
        addUpload: "Upload Thesis",
        approveUpload: "Approve Upload",
        uploadList: "Upload List",
        updateUpload: "Update Thesis",
        statistics: "Statistics",
        addThesis: "Add Thesis",
        thesisList: "Thesis List",
        updateThesis: "Update Thesis",
        about: "About",
        termsOfService: "Terms of Service",
        privacyPolicy: "Privacy Policy",
        siteName: " - Yunhists"
    }
};