export default {
    home: {
        search: '搜索'
    },
    header: {
        home: '首页',
        thesis: '论文',
        category: '分类',
        statistics: '统计',
        manage: '管理',
        approve: '审批上传',
        maintain: "系统维护",
        login: '登录',
        logout: '退出',
        confirmLogout: '确认退出？',
        confirm: '确定',
        cancel: '取消',
        logoutSuccess: '成功退出',
        logoutCancelled: '已取消退出'
    },
    footer: {
        about: '关于本站',
        term: '服务条款',
        privacy: '隐私政策',
        site: '滇史论辑'
    },
    login: {
        accountLogin: '账户登录',
        email: '邮箱',
        password: '密码',
        forgotPassword: '忘记密码？',
        login: '登录',
        registerAccount: '账户注册',
        username: '用户名',
        confirmPassword: '确认密码',
        verificationCode: '验证码',
        send: '发送',
        register: '注册',
        noAccount: '没有账户？',
        haveAccount: '已有账户？',
        enterEmail: '请输入邮箱',
        invalidEmail: '邮箱无效',
        enterPwd: '请输入密码',
        incorrectPwd: '密码错误',
        confirm: '确定',
        emailNotRegistered: '该邮箱未注册',
        googleAccount: '该账户由谷歌账户注册，请通过谷歌登录',
        emailAccount: '该账户通过邮箱注册，请使用密码登录',
        welcome: '欢迎，',
        missUsername: '请输入用户名',
        usernameLengthNotMatch: '长度在1到15个字符',
        missPassword: '请输入密码',
        passwordLengthNotMatch: '密码至少有6位',
        missConfirmPassword: '请再次输入密码',
        passwordNotMatch: '密码不一致',
        missVerificationCode: '请输入验证码',
        verificationNotMatch: '验证码是6位数字',
        registerSuccess: "注册成功",
        noVerificationCodeSend: "系统未曾向该邮箱地址发送验证码",
        emailRegistered: "该邮箱已被注册",
        incorrectCode: "验证码错误",
        codeExpired: "验证码已过期",
        codeSent: "验证码已发送，如收件箱中没有邮件，请检查垃圾邮件",
        waitCountDown: "请等倒计时结束再发送",
        sendEmailFail: "邮件发送失败，请检查邮箱地址",
        resetPwd: "重置密码",
        reset: "重置",
        newPwdSent: "新密码已发送，如收件箱中没有邮件，请检查垃圾邮件",
        waitSendEmail: "邮件已发送，请等一会儿再操作",
        caution: "注意",
        cancel: "取消",
        cautionTxt1: "请阅读",
        cautionTxt2: "服务条款",
        cautionTxt3: "与",
        cautionTxt4: "隐私政策",
        cautionTxt5: "，如您继续注册，将视为同意并接受本站的相关规定。",
    },
    profile: {
        userInfo: "用户信息",
        myUpload: "我的上传",
        userId: "ID",
        username: "用户名",
        change: "修改",
        email: "邮箱",
        onlyEmailRegisteredCanChange: "只有邮箱注册的用户可以改绑邮箱，谷歌注册的用户不可以。",
        password: "密码",
        langSetting: "语言设置",
        userRightsGroup: "权限组",
        pointsLevel: "积分等级",
        levelTotal: "一共二十级，上传论文可以获得积分。",
        comma: "，",
        points: "分",
        confirmDeleteAccount: "确认删除账户",
        confirmDeleteAccountInfo: "如您确认删除账户，数据库中您的邮箱、密码、用户名会被清除，您将无法登录账户，上传记录中也无法看到您的用户名。在删除前，请记下您的ID，如您希望恢复账户，请通过电子邮件联系管理员，并提供用户ID。",
        confirm: "确认",
        cancel: "取消",
        accountDeleted: "账户已删除",
        changeUsername: "修改用户名",
        inputNewUsername: "请输入新用户名",
        changeEmail: "改绑邮箱",
        inputNewEmail: "请输入新邮箱",
        inputAccountPwd: "请输入账户密码",
        inputVerificationCode: "请输入验证码",
        changePassword: "修改密码",
        inputOldPwd: "请输入旧密码",
        inputNewPwd: "请输入新密码",
        inputNewPwd2: "请再次输入新密码",
        usernameSame: "用户名相同，无修改",
        usernameLength: "用户名1-15位字符",
        changeSuccess: "修改成功",
        emailSame: "邮箱相同，无修改",
        invalidEmail :"邮箱格式错误",
        codeSent: "验证码已发送，如收件箱中没有邮件，请检查垃圾邮件",
        waitCountDown: "请等倒计时结束再发送",
        sendEmailFail: "邮件发送失败，请检查邮箱地址",
        input6VerificationCode: "请输入六位数验证码",
        incorrectPwd: '密码错误',
        codeExpired: "验证码已过期",
        incorrectCode: "验证码错误",
        noVerificationCodeSend: "系统未曾向该邮箱地址发送验证码",
        newPwdNotMatch: "新密码不一致",
        passwordLengthNotMatch: "密码至少有6位",
        send: "发送",
        emailRegistered: "该邮箱已被注册",
        account: "账户信息",
        registration: "注册方式",
        emailRegistration: "邮箱注册",
        googleRegistration: "谷歌注册",
        preferenceSetting: "参数设置",
        settings: "设置",
        description: "描述",
        operation: "操作",
        on: "开",
        off: "关",
        lang: "语言",
        langDesc: "网站显示的语言，电子邮件也将以该语言发送",
        receiveEmail: "接收通知邮件",
        receiveEmailDesc: "接收论文上传成功、失败以及删除的通知邮件",
        deleteAccount: "注销账户",
        deleteAccountDesc: "数据库中您的邮箱、密码、用户名会被清除，您将无法登录账户，上传记录中也无法看到您的用户名",
        pointsRequire1: "升到下一级还需要",
        pointsRequire2: "积分"
    },
    util: {
        permission: {
            noLogin: "您没有登录！",
            noPermission: "您没有权限访问本页面"
        },
        request: {
            serverError: "服务器错误",
            noUser: "用户不存在，请重新登陆",
            tokenExpired: "Token过期，请重新登陆",
            tokenError: "Token错误，请重新登陆",
            missingToken: "Token不存在，请重新登陆",
            unknownError: "未知错误，请重新登陆",
            loginAgain: "请重新登陆",
            noPermission: "您没有权限！",
            unexpectedError: "未知错误",
            timeout: "网络超时，请检查网络"
        }
    },
    category: {
        addCategory: "添加分类",
        zhName: "中文名",
        enName: "英文名",
        parentCat: "上级分类",
        confirm: "确认",
        noPermissionVisit: "您没有权限访问本页面",
        inputZhName: "请输入中文名称",
        inputEnName: "请输入英文名称",
        addSuccess: "添加成功",
        zhCatExist: "该中文分类已存在",
        enCatExist: "该英文分类已存在",
        inputCatName: "请输入分类名称",
        searchCatName: "请搜索分类中文名",
        operate: "操作",
        add: "新增",
        thesesCount: "论文数量",
        subCatsCount: "子分类数量",
        batchOperate: "批量操作",
        forFollowingCats: "为以下分类：",
        addNewCommonParentCats: "添加共同上级分类",
        cancel: "取消",
        rows: "行",
        selectCategory: "请勾选要操作的分类",
        selectParentCat: "请选择要添加的上级分类",
        batchSuccess: "批量操作成功",
        error: "错误",
        followingFails: "以下更新失败",
        selectedCat: "选中的分类",
        notInDatabase: "数据库中不存在。",
        connectTo: "连接至",
        failedBecause: "失败，原因：",
        canNotBeCatItself: "不能成为自己的子分类",
        relationExist: "该从属关系已存在",
        noData: "暂无数据",
        tips: "提示",
        clickGoToDetailPage: "点击分类名可以进入详情页哦",
        homeCat: "总目",
        categoryIdNotExist: "分类ID不存在",
        invalidId: "无效ID",
        edit: "编辑",
        updateCatName: "修改分类名称",
        updateSuccess: "修改成功",
        theses: "论文",
        subCats: "子分类",
        searchCategory: "请搜索分类",
        save: "保存",
        selectedCategoryExist: "所选上级分类已存在",
        canNotBeCatItselfDetailPage_1: "添加至",
        canNotBeCatItselfDetailPage_2: "分类下失败，原因：不能成为自己的子分类",
        delete: "删除",
        selectSubCatsAndTheses: "请选择要操作的子分类或论文",
        selectedTheses: "选中的论文",
        removeFromCurrentCat: "从本分类移除",
        confirmRemove1: "确认从分类",
        confirmRemove2: "中移除？",
        moveToAnotherCat: "移动至另一分类",
        noNeedMove: "所选项已在本分类下，无需移动",
        alreadyInThisCatDetailPage: "已经在这个分类页了哦",
        confirmDeleteCat: "确认删除分类？",
    },
    thesis: {
        dragOr: "将文件拖到此处，或",
        clickUpload: "点击上传",
        onlyUploadOne: "只能上传一个文件",
        removeSuccess: "成功移除",
        error: "错误",
        uploadSuccess: "上传成功",
        confirm: "确定",
        addThesis: "添加论文",
        author: "作者",
        title: "标题",
        publication: "刊物",
        type: "类型",
        journal: "期刊论文",
        collection: "辑刊论文",
        bookChapter: "专章论文",
        newspaperArticle: "报刊论文",
        location: "出版地",
        publisher: "出版社",
        year: "年份",
        select: "选择",
        volume: "卷号",
        issue: "期号",
        pages: "页码",
        onlinePublisher: "网络出版商",
        onlineUrl: "网络出版链接",
        copyright: "版权状态",
        copyrightReserved: "受版权保护",
        openAccess: "开放获取",
        publicDomain: "公有领域",
        uploadFile: "上传文件",
        category: "分类",
        noPermissionVisit: "您没有权限访问本页面",
        cnki: "中国知网",
        cqvip: "维普资讯",
        selfPublish: "自主出版",
        inputTitle: "请输入论文标题",
        pleaseUploadFile: "请上传论文",
        thesisExist: "论文已存在",
        parseJSON: "解析JSON",
        pleaseUse: "请使用",
        tampermonkey: "本站配套的Tampermonkey脚本",
        inputGeneratedJSON: "生成JSON，并将JSON贴入输入框",
        inputJson: "输入JSON",
        cancel: "取消",
        parse: "解析",
        jsonFormatError: "JSON格式错误",
        autoInput: "自动填入",
        addSuccess: "新增成功",
        invalidCatId: "警告：以下分类ID失效，可能已被删除：",
        volumeIsNum: "卷号必须是数字",
        searchTitle: "搜索标题",
        advancedSearch: "高级搜索",
        operation: "操作",
        add: "新增",
        upload: "上传",
        noData: "暂无数据",
        yearIssue: "年期",
        reference: "引用",
        vancouver: "温哥华参考文献格式",
        copySuccess: "复制成功",
        harvard: "哈佛参考文献格式",
        gbt7714: "信息与文献　参考文献著录规则",
        wikipedia: "维基百科Cite模板",
        downloadThesis: "下载论文",
        download: "下载",
        rows: "行",
        oops: "哎呀，出错了",
        publisherAllRights: "由出版方保留所有权利",
        isOpenAccess: "为开放获取论文",
        isPublicDomain: "属于公有领域",
        noMoreDownload: "您今天的下载次数已用完",
        onlinePublisherIs: "，在线出版商是",
        pleaseClick: "，请点击",
        link: "链接",
        goToPublisherPage: "前往出版商页面",
        purchase: "购买",
        toDownload: "下载",
        dote: "。",
        weHaveResource: "该论文属于公有领域，本站可以提供资源，请",
        click: "点击",
        login: "登录",
        sorryCopyrightNoResource: "很抱歉，由于论文受到著作权保护，本站不能提供资源。",
        youHave: "您今天还有",
        downloadChance: "次下载机会，是否确定下载？",
        youCanIncreaseDownload: "您可以通过",
        uploadThesis: "上传论文",
        youCanIncreaseDownload2: "增加每天的下载机会。",
        noFileInDatabase: "数据库内找不到这个文件，可能已被删除",
        fileMissing: "该论文的文件已遗失，请联系管理员",
        invalidParamInPath: "路径包含无效参数",
        updateThesis: "修改论文",
        thesisIdNotExist: "论文ID不存在",
        invalidId: "无效ID",
        updateSuccess: "更新成功",
        confirmDelete: "确认删除",
        pleaseInputReason: "请输入理由：",
        notMeetCriteriaTitle: "不符合标准",
        notMeetCriteriaBody: "该论文不符合系统的收录标准",
        repeatThesisTitle: "论文重复",
        repeatThesisBody: "系统已收录此论文",
        delete: "删除",
        deleteSuccess: "删除成功",
        selectThesis: "请勾选要操作的论文",
        selectParentCat: "请选择要添加的上级分类",
        batchSuccess: "批量操作成功",
        addNewCommonParentCats: "添加共同上级分类",
        batchOperate: "批量操作",
        forFollowingTheses: "为以下论文：",
        uploadNewThesis: "上传论文",
        newCategory: "新建分类",
        newCategoryComments: "如果上面找不到您希望的分类，请在这里填写。可以同时提供新建分类的归属关系，以供管理员参考",
        export: "导出",
        exportInfo: "将本页面内的论文数据导出为：",
    },
    upload: {
        uploadSuccess: "上传成功，审批结果会通过邮件通知您",
        pleaseSearchTitle: "请搜索标题",
        status: "状态",
        waitingApprove: "待审批",
        approved: "已通过",
        failed: "未通过",
        updateUpload: "修改上传的论文",
        notYourUpload: "这不是你上传的论文",
        updateSuccess: "修改成功",
        approvedUploadCanNotUpdate: "此上传已被审批，不能修改",
        uploadList: "上传列表",
        filterUnapproved: "过滤未审批的上传",
        uploader: "上传者",
        approver: "审批者",
        approve: "审批",
        fileMissingTitle: "缺少文件",
        fileMissingBody: "您上传的论文缺少文件",
        approveNewUpload: "审批上传",
        download: "下载",
        reject: "驳回",
        approvePass: "批准",
        confirmReject: "确认驳回",
        fileMissing: "文件缺失",
        checkFile: "请下载文件并检查",
        approveSuccess: "批准成功",
        inputReason: "请输入理由",
        rejectSuccess: "驳回成功",
        file: "文件",
    },
    admin: {
        missingFile: "缺失文件的论文",
        noCatThesis: "缺失分类的论文",
        noCatCat: "缺失分类的分类",
        noChildCat: "空分类",
        noData: "暂无数据"
    },
    statistics: {
        thesisCount: "论文总数",
        categoryCount: "分类总数",
        relationshipCount: "分类关系数",
        userCount: "用户数",
        uploadCount: "上传总数",
        yearLineChartTitle: "历年论文数量折线图",
        typePieChartTitle: "论文类型比例图",
        copyrightPieChart: "论文版权比例图",
        comingSoon: "即将推出",
        year: "年份：",
        count: "数量：",
        dataRefreshed: "数据已刷新",
        refreshCountdown: "刷新倒计时",
        refreshCountdownPopup: "北京时间0点刷新"
    },
    title: {
        home: "首页",
        missingFile: "缺少文件的论文列表",
        noCatCat: "缺少分类的分类列表",
        noCatThesis: "缺少分类的论文列表",
        emptyCat: "空分类列表",
        addCat: "添加分类",
        catList: "分类列表",
        login: "登录",
        myUpload: "我的上传",
        userInfo: "用户信息",
        addUpload: "上传论文",
        approveUpload: "审批上传",
        uploadList: "上传列表",
        updateUpload: "修改论文",
        statistics: "数据统计",
        addThesis: "添加论文",
        thesisList: "论文列表",
        updateThesis: "修改论文",
        about: "关于本站",
        termsOfService: "服务条款",
        privacyPolicy: "隐私政策",
        siteName: " - 滇史论辑"
    }
};