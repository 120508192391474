<template>
  <div class="service-container">
    <div class="service-title">
      Terms of Service
    </div>
    <div class="service-body">
      <p>No time to write.</p>
      <p>This is a beta version only, all user data and modifications will be cleared after the beta is over.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "zh"
}
</script>

<style lang="less" scoped>
.service-container {
  margin: 20px 10% 50px;
}
.service-title {
  text-align: center;
  line-height: 100px;
  font-size: 2em;
  font-weight: bold;
}
.service-body {
  font-size: 1.3em;
  line-height: 30px;
  p {
    margin-bottom: 20px;
  }
}
</style>